<template>
  <div class="uploadBox">
    <div class="ArrBox">
      <!-- 导航 -->
      <div class="title" v-if="routeName == 'uploadRes'">
        <p>
          当前位置：四名工作室>研修资源>
          <span v-if="type == 1">{{ this.$route.query.id ? '修改' : '上传' }}微课资源</span>
          <span v-else>{{ this.$route.query.id ? '修改' : '上传' }}文档资源</span>
        </p>
      </div>
      <UploadSet :folderFiles="true"></UploadSet>
    </div>
  </div>
</template>

<script>
import UploadSet from "./components/UploadSet";
export default {
  data() {
    return {
      type: "",
      routeName: "",
      ids: "",
    };
  },
  components: {
    UploadSet,
  },
  created() {
    this.routeName = this.$route.name
    this.type = this.$route.query.type
  },
};
</script>
<style lang="less" scoped>
.uploadBox {
  // background: #f7f7f7;
  padding-bottom: 40px;
  box-sizing: border-box;

  .ArrBox {
    width: 1400px;
    margin: 0 auto;

    .title {
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;

      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

  }
}
</style>